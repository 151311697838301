import React from "react";
import {Button, Snackbar} from "@mui/material";

export default function Cookies() {
    let cookies = getCookie("cookies");
    cookies = cookies === "true";
    const [open, setOpen] = React.useState(!cookies);

    const closeSnackbar = () => {
        setOpen(false);
        setCookie("cookies", true, 60);
    };

    const action = (
        <Button onClick={closeSnackbar} color={"primary"}>
            OK
        </Button>
    );

    return (
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            open={open}
            message={"This site uses cookies to deliver its services and to analyse traffic."}
            action={action}
        />
    );
}

// https://www.w3schools.com/js/js_cookies.asp
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}