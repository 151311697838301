import {createTheme} from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1e88e5",
    },
    background: {
      // default: "#171717",
    },
  },
  typography: {
    fontFamily: [
      'Joan',
      'serif',
    ].join(','),
  },
});