import {SvgIcon} from "@mui/material";
import {theme} from "../theme";

export default function Logo() {
  return (
    <SvgIcon fontSize={"large"}>
      <svg height="800" width="800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.737 489.737"
           fill={theme.palette.primary.main}>
        <path
          d="M266.375 0c-28.4 0-55.7 6.2-79.9 18-63.9 29.9-108.2 94.4-108.2 169.1V218l-38.1 73.2c-9.8 18.6-.5 33.5 20.1 33.5h18v69c0 21.6 17.5 39.2 39.2 39.2l54.1-8.2v54.6c0 6.7 6.2 11.3 13.4 10.3l194.3-34.5c7.2-1.5 13.4-8.2 13.4-16V324.6c37.1-34 60.8-83 60.8-137.6-.5-103-84-187-187.1-187zm92.8 278.7c-43.1 43.1-108.8 49.7-158.9 20.4l-.4-.4c-19.5 15.1-40.8 17.3-55.9 15.1-4.9-.9-5.8-7.5-1.3-9.8 13.3-6.7 22.2-20 27.1-30.6l-.9-.9c-45.7-51.5-43.9-130.1 5.3-179.3 51.1-51.5 134.1-51.5 185.1 0 51 51 51 134-.1 185.5z"/>
        <path
          d="m333.875 201.3-62 62c-1.3 1.3-3.1 2-4.9 2s-3.6-.7-4.9-2l-62.2-62.1c-8.6-8.6-13.4-20.1-13.4-32.2 0-12.2 4.7-23.6 13.3-32.3 8.6-8.6 20-13.3 32.2-13.3s23.7 4.8 32.3 13.4l2.5 2.5 2.5-2.5c8.6-8.6 20.1-13.4 32.3-13.4 12.2 0 23.6 4.7 32.2 13.4 8.6 8.6 13.4 20.1 13.3 32.3.1 12.1-4.6 23.6-13.2 32.2z"/>
      </svg>
    </SvgIcon>
  )
}