import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import Logo from "./Logo";


export default function Masthead() {
  return (
    <AppBar position="fixed" elevation={0} sx={{backdropFilter: "blur(10px)", backgroundColor: "#11111188"}}>
      <Toolbar>
        <IconButton size="large" edge="start">
          <Logo/>
        </IconButton>
        <Typography variant="h5" fontWeight={"bold"} color={"primary"}>
          Tacit
        </Typography>
      </Toolbar>
    </AppBar>
  )
}