import React from 'react';
import Masthead from "./Masthead";
import Statement2 from "./Statement2";
import Footer from "./Footer";
import Hero2 from "./Hero2";
import Background from "./Background";
import Cookies from "./Cookies";

function App() {
    return (
        <>
            <Background/>
            <Masthead/>
            <Hero2/>
            <Statement2/>
            <Footer/>
            <Cookies/>
        </>
    );
}

export default App;
