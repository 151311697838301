import CrossfadeCarousel from "./CrossfadeCarousel";
import InLove from "../res/in_love.svg";
import Cooking from "../res/cooking.svg";
import FunMoments from "../res/fun_moments.svg";
import HappyNewYear from "../res/happy_new_year.svg";
import PersonalTrainer from "../res/personal_trainer.svg";
import Relaxation from "../res/relaxation.svg";
import Travelling from "../res/travelling.svg";
import WinterSkating from "../res/winter_skating.svg";
import WalkingTogether from "../res/walking_together.svg";
import {Box} from "@mui/material";

export default function Background() {
    const images = [
        WalkingTogether,
        Cooking,
        InLove,
        Travelling,
        FunMoments,
        HappyNewYear,
        PersonalTrainer,
        Relaxation,
        WinterSkating,
    ]

    return (
        <Box sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: -10,
            opacity: "50%",
        }}>
            <Box width={512} height={512} maxWidth={"100%"} maxHeight={"100%"}>
                <CrossfadeCarousel images={images} interval={3000} transition={2000}/>
            </Box>
        </Box>
    )
}